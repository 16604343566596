import React from "react";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";

const GradientTransition = ({ topColor = "#e0eaff", bottomColor = "#e8effd", height = 200 }) => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      sx={{
        position: "relative",
        width: "100%",
        height: `${height}px`,
        marginTop: "-1px",
        background: `linear-gradient(to bottom, ${topColor} 0%, ${bottomColor} 100%)`,
        overflow: "hidden",
        boxShadow: "none",
        border: "none",
        zIndex: 1,
      }}
    >
      {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Parallax
          speed={-15} // Negative value makes it move slower than scroll, creating the background effect
          style={{
            position: "absolute",
            bottom: 0,
            // left: 0,
            // right: 0,
            // height: "100%",
            // zIndex: 1,
            // overflow: "hidden",
            mt: 5,
          }}
        >
          <Box
            component="img"
            src="/ziaSymbol.avif"
            sx={{
              height: 100,
              mx: 2,
              color: "#FAD461",
              fontSize: 16,
              //   transform: "rotate(45deg)",
              display: "inline-block",
            }}
          ></Box>
        </Parallax>
      </Box> */}
    </Box>
  );
};

export default GradientTransition;
