import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Paper, Typography, Box, Container } from "@mui/material";
import { keyframes } from "@emotion/react";
import { motion } from "framer-motion";
import TestimonialCarousel from "./TestimonialCarousel";

const image = "/DRLawLogo2025.png";

const HeroPaper = styled(Paper)(({ theme, loaded }) => ({
  minHeight: "100vh",
  height: "100vh",
  backgroundColor: loaded ? "transparent" : "#3F4050",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  transition: "background-image 0.5s ease-in-out",
  overflow: "hidden",
  marginBottom: 0,
  border: "none",
  boxShadow: "none",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "125px",
    background: "linear-gradient(to bottom, transparent, #a2c0ff)",
    zIndex: 2,
  },
  [theme.breakpoints.down("sm")]: {
    height: "60vh",
  },
}));

// Content container to position elements
const ContentContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "100%",
  position: "relative",
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

// Left side modified to hold the testimonial carousel
const RightSide = styled(Box)(({ theme }) => ({
  flex: "0 0 40%",
  display: "flex",
  flexDirection: "column",
  // justifyContent: "space-between",

  [theme.breakpoints.down("md")]: {
    flex: "none",
  },
}));

// Logo container with enhanced positioning - without entrance animation
const LogoContainer = styled(Box)(({ theme }) => ({
  flex: "0 0 50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flex: "0 0 90%",
  },
  [theme.breakpoints.down("sm")]: {
    flex: "0 0 100%",
  },
}));

const Placeholder = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  height: "100vh",
  backgroundColor: "#3F4050",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Hero = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = image;
  }, []);

  return (
    <>
      {!loaded && <Placeholder />}
      <HeroPaper loaded={loaded} elevation={0}>
        {/* {loaded && <BackgroundPatterns />} */}
        <ContentContainer maxWidth="xl">
          <LogoContainer>
            <motion.div
              initial={{
                opacity: 0,
                x: -100,
                scale: 0.9,
                filter: "drop-shadow(0px 5px 10px rgba(26, 71, 137, 0.1))",
              }}
              animate={[
                // First animation: entrance from left
                {
                  opacity: 1,
                  x: 0,
                  scale: 1,
                  filter: "drop-shadow(0px 20px 25px rgba(26, 71, 137, 0.3))",
                  transition: {
                    duration: 1.8,
                    ease: [0.22, 1, 0.36, 1],
                  },
                },
                // Second animation: continuous floating effect
                {
                  y: [0, -4, 0],
                  rotateX: [-1, 1, -1],
                  rotateY: [-1, 1, -1],
                  transition: {
                    delay: 1.8, // Start after entrance completes
                    y: {
                      repeat: Infinity,
                      duration: 4,
                      ease: "easeInOut",
                    },
                    rotateX: {
                      repeat: Infinity,
                      duration: 5,
                      ease: "easeInOut",
                    },
                    rotateY: {
                      repeat: Infinity,
                      duration: 6,
                      ease: "easeInOut",
                    },
                  },
                },
              ]}
              style={{
                transformStyle: "preserve-3d",
                perspective: "1000px",
                transformOrigin: "center center",
              }}
            >
              <img
                src="/DRLawLogo2025.png"
                alt="David Rhee Injury Law"
                style={{
                  maxWidth: "100%",
                  width: "auto",
                  maxHeight: "75vh",
                  filter: "drop-shadow(0px 20px 25px rgba(26, 71, 137, 0.3))",
                  "@media (max-width: 600px)": {
                    maxWidth: "90%",
                    maxHeight: "40vh",
                  },
                }}
                sx={{
                  maxWidth: { xs: "90%", sm: "90%", md: "100%" },
                  maxHeight: { xs: "40vh", sm: "50vh", md: "75vh" },
                }}
              />
            </motion.div>
          </LogoContainer>
          <RightSide>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.8, // Start after logo animation begins
                  duration: 1.5,
                  ease: "easeOut",
                },
              }}
            >
              <TestimonialCarousel />
            </motion.div>
          </RightSide>
        </ContentContainer>
      </HeroPaper>
    </>
  );
};

export default Hero;
