import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography, Box, Paper, Button, Container, Divider, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import theme from "../../Theme/theme";
import styled from "@emotion/styled";
import { useInView } from "react-intersection-observer";

// Import your existing dialog component if available
import CustomizedDialogs from "./EachServiceDialog";
const image = "/sandia2Dark.png";
const ServicesRefined = () => {
  const [open, setOpen] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  //   const ref = useRef(null);
  //   const [isInView, setIsInView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const SectionTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    color: "#1a4789",
    fontWeight: 700,
    fontSize: "5rem",
    letterSpacing: "0.02em",
    position: "relative",
    paddingBottom: theme.spacing(2),
    textTransform: "none",
    fontFamily: "serif",
    lineHeight: 1.2,
    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
    //   whiteSpace: "nowrap",

    [theme.breakpoints.down("lg")]: {
      fontSize: "3rem",
    },
  }));

  const SectionTitle2 = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    color: "#1a4789",
    fontWeight: 700,
    fontSize: "2rem",
    letterSpacing: "0.02em",
    position: "relative",
    paddingBottom: theme.spacing(2),
    textTransform: "none",
    fontFamily: "serif",
    lineHeight: 1.2,
    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
    //   whiteSpace: "nowrap",

    [theme.breakpoints.down("lg")]: {
      fontSize: "1.3rem",
    },
  }));

  const handleClickOpen = (panel) => {
    setOpen(panel);
  };

  const handleClose = () => {
    setOpen("");
  };

  // Services data
  const services = [
    {
      title: "Auto Accidents",
      description:
        "Expert representation for victims of car and truck crashes, seeking maximum compensation for injuries and damages.",
      panel: "panel1",
    },
    {
      title: "Premises Liability",
      description:
        "Unsafe properties can cause life-changing injuries in an instant. We'll help you hold property owners accountable for their negligence.",
      panel: "panel2",
    },
    {
      title: "Medical Malpractice",
      description:
        "When healthcare providers break your trust, the consequences can be devastating. We'll advocate for your rights and pursue the compensation you deserve.",
      panel: "panel3",
    },
    {
      title: "DUI/DWI Accidents",
      description:
        "When impaired drivers shatter lives, we fight for justice. Let our expertise guide you through recovery while we hold the responsible parties accountable.",
      panel: "panel4",
    },
    {
      title: "Dog Bites",
      description:
        "Vicious attacks leave more than physical scars. We'll fight for your complete recovery while holding negligent owners responsible.",
      panel: "panel5",
    },
    {
      title: "Gun Violence",
      description:
        "When senseless gun violence disrupts innocent lives, we stand ready to pursue justice. Let us help you navigate the path to healing and accountability.",
      panel: "panel6",
    },
  ];

  // Container animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  // Item animation variants
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <Box
      id="Practice"
      ref={ref}
      sx={{
        position: "relative",
        pb: 8,
        px: { xs: 2, md: 4 },
        overflow: "hidden",
        backgroundColor: "#e8effd",
        marginTop: "-1px", // Eliminate any gap from the transition
        marginBottom: "-1px",
      }}
    >
      <Parallax
        speed={-15}
        style={{
          position: "absolute",
          bottom: -250,
          left: 0,
          right: 0,
          height: "75%",
          zIndex: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${image})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: 0.2,
            maskImage: "linear-gradient(to top, rgba(0,0,0,0.8), transparent)",
            WebkitMaskImage: "linear-gradient(to top, rgba(0,0,0,0.8), transparent)",
          }}
        />
      </Parallax>

      <Container maxWidth="xl" sx={{ position: "relative", zIndex: 2 }}>
        {/* Header Section with Southwest-inspired design */}
        <Box sx={{ textAlign: "center", mb: 10 }}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
            transition={{ duration: 0.8 }}
          >
            <SectionTitle>
              <span color="#1a4789" fontWeight="bold">
                Our Practice Areas
              </span>
              <span
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "80px",
                  height: "4px",
                  background: "linear-gradient(90deg, #FAD461, rgba(250, 212, 97, 0.5))",
                  borderRadius: "4px",
                }}
              ></span>
            </SectionTitle>
          </motion.div>

          {/* Introduction text */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            <Typography
              variant="body1"
              sx={{
                maxWidth: 900,
                mx: "auto",
                px: 2,
                lineHeight: 1.8,
                fontSize: "1.05rem",
                color: "#333333",
              }}
            >
              We provide high-quality legal services to the people of New Mexico. Our firm is dedicated to serving
              clients who have suffered injury due to the negligence or wrongful acts of others. As a bilingual firm, we
              are proud to serve clients who speak both English and Spanish. Our team handles a wide range of injury
              cases, from car crashes to wrongful death, and we are committed to achieving the best possible outcome for
              our clients. Our firm is proud to represent clients in a variety of practice areas, including:
            </Typography>
            {/* Southwest-inspired divider */}
            {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 4 }}>
              <Box
                component="img"
                src="/ziaSymbol.avif"
                sx={{
                  height: 80,
                  mx: 2,
                  color: "#FAD461",
                  fontSize: 16,
                  //   transform: "rotate(45deg)",
                  display: "inline-block",
                }}
              ></Box>
            </Box> */}
          </motion.div>
        </Box>

        {/* New Mexico-inspired service panels */}
        <motion.div variants={containerVariants} initial="hidden" animate={inView ? "visible" : "hidden"}>
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div variants={itemVariants}>
                  <Paper
                    elevation={0}
                    sx={{
                      position: "relative",
                      borderRadius: 0,
                      overflow: "hidden",
                      border: "none",
                      backgroundColor: "#ffffff",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        boxShadow: "0 4px 12px rgba(26, 71, 137, 0.15)",
                      },
                      "&:before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "4px",
                        height: "100%",
                        backgroundColor: "#1a4789",
                      },
                    }}
                  >
                    <Box sx={{ p: 3 }}>
                      <Typography
                        variant="h5"
                        component="h3"
                        color="#1a4789"
                        fontWeight="bold"
                        sx={{
                          mb: 1,
                          pb: 1,
                          borderBottom: "1px solid rgba(26, 71, 137, 0.1)",
                        }}
                      >
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2.5,
                          color: "#333333",
                          fontSize: "0.95rem",
                          lineHeight: 1.6,
                        }}
                      >
                        {service.description}
                      </Typography>
                      <Typography
                        variant="button"
                        onClick={() => handleClickOpen(service.panel)}
                        sx={{
                          color: "#1a4789",
                          textTransform: "uppercase",
                          fontWeight: "medium",
                          fontSize: "0.8rem",
                          letterSpacing: 0.5,
                          cursor: "pointer",
                          display: "inline-block",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        LEARN MORE
                      </Typography>
                    </Box>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>

        {/* Call to Action section */}
        <Box
          sx={{
            textAlign: "center",
            mt: 8,
            py: 4,
            position: "relative",
            zIndex: 3,
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            <SectionTitle2>
              <span>Don't Navigate the Legal System Alone!</span>
              <br />
              <span>Hablamos Español</span>
            </SectionTitle2>
            <Button
              variant="contained"
              href="tel:+1-505-589-7008"
              sx={{
                backgroundColor: "#1a4789",
                py: 1.5,
                px: 4,
                borderRadius: 0,
                "&:hover": {
                  backgroundColor: "#0d2e5e",
                },
              }}
            >
              Free Consultation: (505) 589-7008
            </Button>
          </motion.div>
        </Box>
      </Container>

      {/* Include your dialog component here */}
      {CustomizedDialogs && (
        <CustomizedDialogs handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} />
      )}
    </Box>
  );
};

export default ServicesRefined;
