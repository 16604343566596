import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CarAccidents from "./Practices/CarAccidents";
import DwiCrashes from "./Practices/dwi";
import Wheelers from "./Practices/wheeler";
import Death from "./Practices/death";
import Slip from "./Practices/slip";
import Bites from "./Practices/bites";
import Medical from "./Practices/medical";
import Shootings from "./Practices/shootings";
import EachService from "./EachService";
import { serviceDetails } from "./serviceDetails";

export default function CustomizedDialogs(props) {
  let index = serviceDetails.indexOf(serviceDetails.find((x) => x.panel === props.open));

  console.log(index, props.open);

  return (
    props.open && (
      <EachService
        key={serviceDetails[index].id}
        service={serviceDetails[index]}
        panel={props.open}
        handleClose={props.handleClose}
        name={serviceDetails[index].name}
        image={serviceDetails[index].image}
        description={serviceDetails[index].description}
      />
    )
  );

  // <>
  //   <CarAccidents panel={props.open} handleClose={props.handleClose} />
  //   <DwiCrashes panel={props.open} handleClose={props.handleClose} />
  //   {/* <Wheelers panel={props.open} handleClose={props.handleClose} /> */}
  //   <Death panel={props.open} handleClose={props.handleClose} />
  //   <Slip panel={props.open} handleClose={props.handleClose} />
  //   <Bites panel={props.open} handleClose={props.handleClose} />
  //   <Medical panel={props.open} handleClose={props.handleClose} />
  //   <Shootings panel={props.open} handleClose={props.handleClose} />
  // </>
}
