import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Grid,
  Stack,
  Button,
  Divider,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MapIcon from "@mui/icons-material/Map";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";

// Logo import
const logo = "/DRLawLogo2025.png";

// Transition for the dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 0,
    boxShadow: "0 20px 40px rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    background: "#ffffff",
  },
}));

const DialogHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: "#1a4789",
  color: "#ffffff",
  padding: theme.spacing(4, 2),
  textAlign: "center",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: "url(/sandia2Dark.png)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    opacity: 0.1,
    zIndex: 1,
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: "#ffffff",
  zIndex: 10,
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
}));

const SuccessIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
  "& svg": {
    fontSize: 60,
    color: "#FAD461",
  },
}));

const ContactInfoItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const InfoIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: "rgba(26, 71, 137, 0.1)",
  color: "#1a4789",
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(26, 71, 137, 0.1)",
  color: "#1a4789",
  margin: theme.spacing(1),
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#1a4789",
    color: "#ffffff",
    transform: "translateY(-3px)",
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#1a4789",
  color: "#FFFFFF",
  borderRadius: 0,
  padding: theme.spacing(1.5, 4),
  fontWeight: 500,
  position: "relative",
  overflow: "hidden",
  transition: "all 0.3s ease",
  textTransform: "none",
  letterSpacing: "0.5px",
  marginTop: theme.spacing(3),
  boxShadow: "0 4px 10px rgba(26, 71, 137, 0.2)",
  "&:hover": {
    backgroundColor: "#0d2e5e",
    transform: "translateY(-2px)",
    boxShadow: "0 6px 15px rgba(26, 71, 137, 0.3)",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: "2px solid #FAD461",
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  "&:hover:before": {
    opacity: 1,
  },
}));

// Main component
const ContactPopup = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledDialog
      open={props.open}
      onClose={props.handleClose}
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
    >
      <CloseButton aria-label="close" onClick={props.handleClose}>
        <CloseIcon />
      </CloseButton>

      {/* Header Section */}
      <DialogHeader>
        <Box sx={{ position: "relative", zIndex: 2 }}>
          <SuccessIcon>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.2,
              }}
            >
              <CheckCircleOutlineIcon />
            </motion.div>
          </SuccessIcon>
          {isMobile ? null : (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>
                Thank You for Reaching Out
              </Typography>
              <Typography variant="h6">Your message has been received.</Typography>
            </motion.div>
          )}
        </Box>
      </DialogHeader>

      {/* Content Section */}
      <DialogContent sx={{ p: isTablet ? 3 : 5 }}>
        <Grid container spacing={4}>
          {/* Left side - Message */}
          <Grid item xs={12} md={7}>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <Typography variant="h5" color="#1a4789" fontWeight={600} sx={{ mb: 3, position: "relative" }}>
                We Appreciate Your Interest
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "-8px",
                    left: 0,
                    width: "60px",
                    height: "3px",
                    background: "linear-gradient(90deg, #FAD461, rgba(250, 212, 97, 0.5))",
                    borderRadius: "4px",
                  }}
                />
              </Typography>

              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.7 }}>
                We will respond to your inquiry promptly. Our team is dedicated to providing exceptional legal
                representation to help you navigate through your situation.
              </Typography>

              <Typography variant="body1" sx={{ mb: 3, lineHeight: 1.7 }}>
                If your matter requires immediate attention, please don't hesitate to call our office. As a
                client-focused firm, we prioritize clear communication and responsive service.
              </Typography>

              <Typography variant="h6" fontWeight={600} color="#1a4789" sx={{ mt: 4, mb: 2 }}>
                <span style={{ borderBottom: "2px solid #FAD461" }}>Hablamos Español</span>
              </Typography>

              <ActionButton href="tel:+1-505-589-7008" startIcon={<PhoneIcon />}>
                Call Now: (505) 589-7008
              </ActionButton>
            </motion.div>
          </Grid>

          {/* Right side - Contact info */}
          <Grid item xs={12} md={5}>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(232, 239, 253, 0.5)",
                  p: 3,
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "4px",
                    height: "100%",
                    background: "linear-gradient(to bottom, #1a4789, #a2c0ff)",
                  },
                }}
              >
                <Typography variant="h5" color="#1a4789" fontWeight={600} sx={{ mb: 3 }}>
                  Contact Information
                </Typography>

                <ContactInfoItem>
                  <InfoIcon>
                    <MapIcon fontSize="small" />
                  </InfoIcon>
                  <Box>
                    <Typography fontWeight={500}>300 Central Ave. SW Suite 3000</Typography>
                    <Typography>Albuquerque, New Mexico 87102</Typography>
                  </Box>
                </ContactInfoItem>

                <ContactInfoItem>
                  <InfoIcon>
                    <PhoneIcon fontSize="small" />
                  </InfoIcon>
                  <Box>
                    <Typography fontWeight={500}>
                      <a href="tel:+1-505-589-7008" style={{ textDecoration: "none", color: "#1a4789" }}>
                        (505) 589-7008
                      </a>
                    </Typography>
                    <Typography variant="body2" color="#666666">
                      Phone
                    </Typography>
                  </Box>
                </ContactInfoItem>

                <ContactInfoItem>
                  <InfoIcon>
                    <EmailIcon fontSize="small" />
                  </InfoIcon>
                  <Box>
                    <Typography fontWeight={500}>(505) 273-8990</Typography>
                    <Typography variant="body2" color="#666666">
                      Fax
                    </Typography>
                  </Box>
                </ContactInfoItem>

                <Divider sx={{ my: 2 }} />

                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Follow Us
                  </Typography>
                  <Stack direction="row" justifyContent="center">
                    <SocialButton aria-label="instagram" href="https://www.instagram.com/davidrheelaw/" target="_blank">
                      <InstagramIcon fontSize="small" />
                    </SocialButton>
                    <SocialButton
                      aria-label="location"
                      href="https://maps.app.goo.gl/LwF9ukaVNKeSaaET9"
                      target="_blank"
                    >
                      <MapIcon fontSize="small" />
                    </SocialButton>
                  </Stack>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <motion.div
                    animate={{
                      y: [0, -5, 0],
                      transition: {
                        y: { repeat: Infinity, duration: 3, ease: "easeInOut" },
                      },
                    }}
                  >
                    <img
                      src={logo}
                      alt="David Rhee Law"
                      style={{
                        maxWidth: "140px",
                        height: "auto",
                        filter: "drop-shadow(0px 5px 10px rgba(26, 71, 137, 0.2))",
                      }}
                    />
                  </motion.div>
                </Box>
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

ContactPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ContactPopup;
