import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography, Box, Paper } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Container for the testimonial section
const TestimonialContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  height: "100%",
  padding: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    display: "none", // Hide on mobile (same as your LeftSide component)
  },
}));

// Testimonial card styling
const TestimonialCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  background: "rgba(255, 255, 255, 0.85)",
  borderRadius: theme.spacing(2),
  backdropFilter: "blur(8px)",
  boxShadow: "0px 10px 20px rgba(26, 71, 137, 0.15)",
  width: "100%",
  maxWidth: "800px",
  position: "relative",
  overflow: "hidden",
  margin: theme.spacing(2, 0),
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "5px",
    height: "100%",
    background: "linear-gradient(to bottom, #1a4789, #a2c0ff)",
  },
}));

// Large quote mark styling
const QuoteMark = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "-8px",
  right: "20px",
  fontSize: "90px",
  fontFamily: "Georgia, serif",
  color: "#1a4789",
  lineHeight: 1,
  paddingTop: "10px",
  paddingBottom: "10px",
}));

// Testimonial author styling
const AuthorText = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginTop: theme.spacing(2),
  color: theme.palette.primary.main,
}));

// Title for the testimonials section
const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: "#1a4789",
  fontWeight: 700,
  fontSize: "2.5rem",
  letterSpacing: "0.02em",
  position: "relative",
  paddingBottom: theme.spacing(2),
  textTransform: "none",
  fontFamily: "serif",
  lineHeight: 1.2,
  textShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
  //   whiteSpace: "nowrap",
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "4px",
    width: "80px",
    background: "linear-gradient(90deg, #FAD461, rgba(250, 212, 97, 0.5))",
    borderRadius: "4px",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "2rem",
  },
}));

// Sample testimonial data - you would replace this with your real testimonials
const testimonials = [
  {
    id: 1,
    quote:
      "If I could give David Rhee 100 ⭐ I would! He's literally the best! Always returning calls fast! Answers my questions. And if I don't understand something he explains it very well for me! He got me what I deserved and fought for me! Give him a call! You won't regret having him on your side!",
    author: "Brittney Babcock",
    case: "Auto Accident",
  },
  {
    id: 2,
    quote:
      "David is a very good lawyer. He was very helpful getting me the compensation that I needed. His communication and dedication to the case was impressive. I was very happy with his law office.",
    author: "Mark Simma",
    case: "Slip and Fall Case",
  },
  {
    id: 3,
    quote:
      "David has helped me tremendously throughout my experience in seeking justice from a very scary car accident. I was pregnant when the accident occurred, I was lost and worried about what to do next. He guided me throughout every single process, kept me informed, and most importantly, cared about me and my child’s well being. ",
    author: "Michelle Walters",
    case: "Car Accident Settlement",
  },
  {
    id: 4,
    quote:
      "I can’t thank David enough for his kindness and attentiveness and I will always refer people to him for his reliability and punctuality. Words can’t express my gratitude - thank you!",
    author: "Michelle Walters",
    case: "Car Accident Settlement",
  },
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: false,
  });

  // Auto-rotate testimonials
  useEffect(() => {
    if (inView) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      }, 8000); // Change testimonial every 8 seconds

      return () => clearInterval(interval);
    }
  }, [inView]);

  return (
    <TestimonialContainer ref={ref}>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: -30 }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1.2,
              ease: [0.22, 1, 0.36, 1],
            },
          }}
          exit={{
            opacity: 0,
            x: 30,
            transition: {
              duration: 0.8,
            },
          }}
          style={{ width: "100%" }}
        >
          <SectionTitle>
            <span style={{ zIndex: 2, whiteSpace: "nowrap" }}>Just Results</span>
          </SectionTitle>
          <TestimonialCard elevation={4}>
            <QuoteMark>"</QuoteMark>
            <Typography
              variant="body1"
              fontSize={{ sm: "small", md: "medium" }}
              sx={{ position: "relative", zIndex: 2, pt: 2 }}
            >
              {testimonials[currentIndex].quote}
            </Typography>
            <AuthorText variant="subtitle1">{testimonials[currentIndex].author}</AuthorText>
            <Typography variant="caption" color="text.secondary">
              {testimonials[currentIndex].case}
            </Typography>
          </TestimonialCard>
        </motion.div>
      </AnimatePresence>

      {/* Indicator dots */}
      <Box sx={{ display: "flex", mt: 2, justifyContent: "center", width: "100%" }}>
        {testimonials.map((_, index) => (
          <Box
            key={index}
            component={motion.div}
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              mx: 0.5,
              bgcolor: currentIndex === index ? "primary.main" : "rgba(255, 255, 255, 0.5)",
              cursor: "pointer",
            }}
            whileHover={{ scale: 1.2 }}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </Box>
    </TestimonialContainer>
  );
};

export default TestimonialCarousel;
