import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: '#00bcd4',
  //   },
  //   secondary: {
  //     main: '#ff5722',
  //   },
  // },
  typography: {
    fontFamily: "'Heebo', serif, Arial, sans-serif",
    body1: {
      fontFamily: "'Heebo', sans-serif",
      fontSize: "22px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 2000,
    },
  },
  components: {
    // Name of the component

    MuiPaper: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // background: "radial-gradient(circle at 70% 50%, #ffffff 0%, #e0eaff 30%, #b8d0ff 60%, #a2c0ff 100%)",
          overflow: "hidden",
          border: "none", // Explicitly remove borders
          boxShadow: "none",
          marginBottom: 0,
          // pb: 0,
          // maxWidth: "md",
          // borderRadius: "0",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#a2c0ff",
            },
            "&:hover fieldset": {
              borderColor: "#a2c0ff",
            },
          },
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#a2c0ff",
          },
        },
      },
    },
  },
});

export default theme;
