import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Icon, SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion, AnimatePresence } from "framer-motion";
import PrivacyPolicy from "./PrivacyPolicy";
const pages = ["About", "Contact", "Practice"];

function ResponsiveAppBar() {
  const [open, setOpen] = React.useState(false);
  const navRef = React.useRef(null);

  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const [scroll, setScroll] = React.useState(false);

  React.useEffect(() => {
    // Listen for scroll event
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check if the user has scrolled
    if (window.scrollY > 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  return (
    <Grid>
      {/* Transparent base AppBar (always visible) */}
      <AppBar
        ref={navRef}
        id={"navbar"}
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          height: "6em",
          backfaceVisibility: "hidden",
          position: "fixed",
          zIndex: 1100,
          borderBottom: "none !important",
          boxShadow: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Animated gradient overlay */}
        <AnimatePresence>
          {scroll && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.0, ease: "easeOut" }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "linear-gradient(45deg, #1a4789 0%, #a2c0ff 80%, #3a5fc1 100%)",
                borderBottom: "none !important",
                boxShadow: "none",
                zIndex: -1,
              }}
            />
          )}
        </AnimatePresence>

        <Container
          maxWidth="auto"
          sx={{
            borderBottom: "none !important",
            boxShadow: "none",
            justifyContent: { xs: "center", md: "" },
          }}
        >
          <Toolbar
            // disableGutters
            sx={{
              borderBottom: "none",
              boxShadow: "none",
              justifyContent: { xs: "center", md: "" },
            }}
          >
            <Box
              pl={xl ? 40 : 0}
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                borderBottom: "none",
                boxShadow: "none",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  href={`#${page}`}
                  sx={{
                    my: 2,
                    mr: 5,
                    color: "#FFFFFF",
                    display: "block",
                    border: "2px solid transparent",
                    transition: "border 0.5s",
                    boxShadow: "none",

                    "&:before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      border: "2px solid #1a4789",
                      transform: "scale(0)",
                      transformOrigin: "0 0",
                      transition: "transform 0.5s",
                      boxShadow: "none",
                    },
                    "&:hover:before": {
                      transform: "scale(1)",
                      boxShadow: "none",
                    },
                  }}
                >
                  <Typography fontSize={"24px"} variant="button">
                    {page}
                  </Typography>
                </Button>
              ))}
            </Box>
            <Grid container justifyContent={md ? "flex-end" : sm ? "center" : "none"} alignItems={"center"}>
              <Grid item>
                <Typography fontSize={md ? "24px" : "22px"} variant="button" color={"#1a4789"} pr={1}>
                  Call Today
                </Typography>
              </Grid>
              <Grid item>
                <a style={{ textDecoration: "none" }} href="tel:+1-505-589-7008">
                  <Typography
                    fontSize={md ? "24px" : "22px"}
                    variant="button"
                    pr={xl ? 40 : md ? 10 : 0}
                    sx={{
                      textDecoration: "none",
                      "&:hover": {
                        color: "#1a4789",
                      },
                    }}
                    color={scroll ? "#FFFFFF" : "#1a4789"}
                    transition="color 2.5s"
                  >
                    (505) 589 - 7008
                  </Typography>
                </a>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </Grid>
  );
}
export default ResponsiveAppBar;
