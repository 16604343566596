import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import Paper from "@mui/material/Paper";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 0,
  },
  "& .MuiPaper-root": {
    borderRadius: 0,
    overflow: "hidden",
    maxWidth: "800px",
    width: "100%",
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  overflow: "hidden",
  backgroundColor: "#ffffff",
}));

const DialogHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 4, 2, 4),
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "5px",
    height: "100%",
    background: "linear-gradient(to bottom, #1a4789, #a2c0ff)",
  },
}));

const DialogImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  height: "250px",
  [theme.breakpoints.down("sm")]: {
    height: "180px",
  },
}));

const DialogBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 4, 4, 4),
}));

const DialogFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  backgroundColor: "#f8f9fa",
  borderTop: "1px solid rgba(26, 71, 137, 0.1)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ServiceIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  backgroundColor: "rgba(26, 71, 137, 0.1)",
  color: "#1a4789",
  position: "absolute",
  top: "20px",
  right: "20px",
  zIndex: 2,
  border: "2px solid white",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
}));

const ContactButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#1a4789",
  color: "#FFFFFF",
  borderRadius: 0,
  padding: theme.spacing(1.2, 3),
  fontWeight: 500,
  position: "relative",
  overflow: "hidden",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#0d2e5e",
    transform: "translateY(-2px)",
    boxShadow: "0 4px 8px rgba(26, 71, 137, 0.3)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: "2px solid #FAD461",
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  "&:hover::before": {
    opacity: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CarAccidents(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="car-accidents-dialog-title"
        open={props.panel === "panel1"}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={fullScreen}
      >
        <ContentWrapper>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: 3,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogImageWrapper>
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: "brightness(0.85)",
              }}
              image="/Services/carCrashDarkMd.jpg"
              title="Car Crashes"
            />

            {/* <ServiceIcon sx={{ position: "absolute", left: 10 }}>
              <DirectionsCarIcon sx={{ fontSize: 30 }} />
            </ServiceIcon> */}
          </DialogImageWrapper>

          <DialogHeader>
            <Typography
              variant="h4"
              component="h2"
              id="car-accidents-dialog-title"
              color="#1a4789"
              fontWeight="bold"
              sx={{
                mb: 1.5,
                pl: 2,
              }}
            >
              Auto Accidents
            </Typography>
            <ServiceIcon sx={{ position: "absolute", right: 30 }}>
              <DirectionsCarIcon sx={{ fontSize: 30 }} />
            </ServiceIcon>
            <Typography
              variant="subtitle1"
              color="#FAD461"
              fontWeight="500"
              sx={{
                pl: 2,
                fontStyle: "italic",
              }}
            >
              Professional representation you can trust
            </Typography>
          </DialogHeader>

          <DialogBody>
            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: "1.1rem",
                  lineHeight: 1.6,
                  color: "#333333",
                }}
              >
                When you are injured in a car accident, recovering from your injuries should be your only priority. Let
                the Law Office of David Rhee deal with the insurance companies and the claims process. David Rhee is
                committed to maximizing your recovery for injuries sustained in car crashes.
              </Typography>
            </motion.div>
          </DialogBody>

          <DialogFooter>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2" color="#1a4789" sx={{ mr: 1 }}>
                Free consultation available
              </Typography>
            </Box>

            <ContactButton href="#Contact" variant="contained" onClick={props.handleClose}>
              Contact Us
            </ContactButton>
          </DialogFooter>
        </ContentWrapper>
      </BootstrapDialog>
    </div>
  );
}
