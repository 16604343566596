export const serviceDetails = [
  {
    id: 1,
    name: "Auto Accidents",
    description:
      "When you are injured in a car accident, recovering from your injuries should be your only priority. Let the Law Office of David Rhee deal with the insurance companies and the claims process. David Rhee is committed to maximizing your recovery for injuries sustained in car crashes.",
    image: "/Services/carCrashDarkMd.jpg",
    panel: "panel1",
  },
  {
    id: 2,
    name: "Premises Liability",
    description:
      "Property owners have a legal duty to maintain safe conditions for visitors and guests. When they fail in this duty, serious injuries can occur without warning. From unmarked hazards to inadequate security, these negligent oversights can lead to life-altering injuries. If you've been hurt on someone else's property, David Rhee Injury Law will fight to hold property owners accountable and secure the compensation you deserve.",
    image: "/Services/darkSlipMd.jpg",
    panel: "panel2",
  },
  {
    id: 3,
    name: "Medical Malpractice",
    description:
      "We trust doctors and medical professionals to take care of each and every patient they care for. Unfortunately, sometimes medical professionals make serious mistakes. If medical negligence has left you with injuries, you need a lawyer who can hold the wrongdoers to account. ",
    image: "/Services/medicalDarkMd.jpg",
    panel: "panel3",
  },
  {
    id: 4,
    name: "DWI Crashes",
    description:
      "Sadly, DWI crashes are all too common in New Mexico. If you or a family member has been the victim of a DWI crash, you will need competent representation. David Rhee uses his knowledge of the law to maximize recovery for victims of DWI crashes. ",
    image: "/Services/dwiDarkMd.jpg",
    panel: "panel4",
  },
  {
    id: 5,
    name: "Dog Bites",
    description:
      "Dog attacks can cause devastating physical injuries and lasting psychological trauma. New Mexico law holds pet owners strictly liable when their animals cause harm. The injuries from these attacks often require extensive medical treatment, resulting in substantial medical bills and potential scarring. If you or a loved one has suffered from a dog bite, David Rhee Injury Law will aggressively pursue your case to ensure you receive full compensation for your medical expenses, pain and suffering, and emotional distress.",

    image: "/Services/dogBiteDarkMd.jpg",
    panel: "panel5",
  },

  {
    id: 6,
    name: "Gun Violence",
    description:
      "When innocent lives are shattered by gun violence, victims need an advocate who understands both the legal complexities and emotional trauma involved. Whether you've been injured in a shooting incident, lost a loved one to gun violence, or been harmed in an armed robbery, our firm has the experience to pursue justice against all responsible parties. David Rhee Injury Law will fight tirelessly to secure the compensation you need while holding accountable those whose negligence or wrongful actions caused your suffering.",
    image: "/Services/shooterDark.jpg",
    panel: "panel6",
  },
];
