import React, { useState, useEffect } from "react";
import { Grid, Typography, IconButton, Box, Stack, Container, Paper, Divider, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import MapIcon from "@mui/icons-material/Map";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ContactForm from "./ContactForm";
import ContactPopup from "./Dialog/ContactPopup";
import { useInView } from "react-intersection-observer";

// Styled components for enhanced visual appeal
const ContactSection = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  position: "relative",
  overflow: "hidden",
  border: "none",
  boxShadow: "none",
  backgroundColor: "#e8effd",
  marginTop: "-1px", // Eliminate any gap from the transition
}));

const ContactPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: 0,
  backgroundColor: "rgba(255, 255, 255, 0.95)",
  boxShadow: "0 8px 32px rgba(26, 71, 137, 0.1)",
  position: "relative",
  overflow: "hidden",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "5px",
    height: "100%",
    background: "linear-gradient(to bottom, #1a4789, #a2c0ff)",
  },
}));

const ContactHeaderWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(4),
}));

const ContactHeaderUnderline = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: -8,
  left: 0,
  width: 80,
  height: 4,
  background: "linear-gradient(90deg, #FAD461, rgba(250, 212, 97, 0.5))",
  borderRadius: "2px",
}));

const InfoIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: "rgba(26, 71, 137, 0.1)",
  marginRight: theme.spacing(2),
  color: "#1a4789",
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(26, 71, 137, 0.1)",
  color: "#1a4789",
  margin: theme.spacing(1),
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#1a4789",
    color: "#ffffff",
    transform: "translateY(-3px)",
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(4),
}));

const ContactPageMD = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Background pattern elements - adds subtle visual interest
  const BackgroundPattern = () => (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: "280px",
        // backgroundImage: "url(/sandia2Dark.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        opacity: 0.1,
        zIndex: 1,
        maskImage: "linear-gradient(to top, rgba(0,0,0,0.8), transparent)",
        WebkitMaskImage: "linear-gradient(to top, rgba(0,0,0,0.8), transparent)",
      }}
    />
  );

  const image = "/DRLawLogo2025.png";

  return (
    <ContactSection id="Contact" ref={ref}>
      <BackgroundPattern />

      <Container maxWidth="xl" sx={{ position: "relative", zIndex: 2, py: 10 }}>
        <Grid container spacing={4}>
          {/* Section Title - Animated on scroll into view */}
          <Grid item xs={12}>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant="h2"
                align="center"
                sx={{
                  color: "#1a4789",
                  fontWeight: 700,
                  fontSize: { xs: "2.5rem", md: "5rem" },
                  letterSpacing: "0.02em",
                  position: "relative",
                  marginBottom: "1rem",
                  paddingBottom: "1rem",
                  textTransform: "none",
                  fontFamily: "serif",
                  lineHeight: 1.2,
                  textShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                }}
              >
                Get In Touch
                <span
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "80px",
                    height: "4px",
                    background: "linear-gradient(90deg, #FAD461, rgba(250, 212, 97, 0.5))",
                    borderRadius: "4px",
                  }}
                ></span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: 900,
                  mx: "auto",
                  px: 2,
                  lineHeight: 1.8,
                  fontSize: "1.05rem",
                  color: "#333333",
                }}
              >
                We understand the importance of clear communication, and we strive to ensure that our clients understand
                their legal options every step of the way. Don't navigate the legal system alone; contact us today for a
                free injury case consultation.
              </Typography>
            </motion.div>
          </Grid>

          {/* Contact Form Section - Left side */}
          <Grid item xs={12} md={7} lg={8}>
            <motion.div
              initial={{ opacity: 0, x: -30 }}
              animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -30 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <ContactPaper elevation={0}>
                <ContactHeaderWrapper>
                  <Typography variant="h4" color="#1a4789" fontWeight={600}>
                    Free Consultations
                  </Typography>
                  <ContactHeaderUnderline />
                </ContactHeaderWrapper>

                <Typography variant="body1" sx={{ mb: 4, color: "#333333" }}>
                  Tell us about your case, and we'll get back to you promptly to discuss how we can help.
                </Typography>

                <ContactForm setOpen={setOpen} />
              </ContactPaper>
            </motion.div>
          </Grid>

          {/* Contact Information Section - Right side */}
          <Grid item xs={12} md={5} lg={4}>
            <motion.div
              initial={{ opacity: 0, x: 30 }}
              animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 30 }}
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              <ContactPaper elevation={0}>
                <Box>
                  <ContactHeaderWrapper>
                    <Typography variant="h4" color="#1a4789" fontWeight={600}>
                      Contact Information
                    </Typography>
                    <ContactHeaderUnderline />
                  </ContactHeaderWrapper>

                  {/* Address */}
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" color="#1a4789" fontWeight={500} sx={{ mb: 2 }}>
                      Office Address
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 40,
                          height: 40,
                          marginRight: theme.spacing(2),
                        }}
                      >
                        <SocialButton
                          aria-label="location"
                          href="https://maps.app.goo.gl/LwF9ukaVNKeSaaET9"
                          target="_blank"
                        >
                          <MapIcon fontSize="small" />
                        </SocialButton>
                      </Box>

                      <Box>
                        <Typography color="#333333" fontWeight={500}>
                          300 Central Ave. SW Suite 3000
                        </Typography>
                        <Typography color="#333333">Albuquerque, New Mexico 87102</Typography>
                      </Box>
                    </Stack>
                  </Box>

                  {/* Contact Details */}
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" color="#1a4789" fontWeight={500} sx={{ mb: 2 }}>
                      Contact Details
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                      <InfoIcon>
                        <PhoneIcon fontSize="small" />
                      </InfoIcon>
                      <Box>
                        <Typography color="#666666" variant="body2">
                          Phone
                        </Typography>
                        <a href="tel:+1-505-589-7008" style={{ textDecoration: "none", color: "#1a4789" }}>
                          <Typography fontWeight={500}>(505) 589-7008</Typography>
                        </a>
                      </Box>
                    </Stack>

                    <Stack direction="row" spacing={2}>
                      <InfoIcon>
                        <EmailIcon fontSize="small" />
                      </InfoIcon>
                      <Box>
                        <Typography color="#666666" variant="body2">
                          Fax
                        </Typography>
                        <Typography fontWeight={500} color="#333333">
                          (505) 273-8990
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>

                  {/* Social Media */}
                  <Box>
                    <Typography variant="h6" color="#1a4789" fontWeight={500} sx={{ mb: 2 }}>
                      Follow Us
                    </Typography>
                    <Stack direction="row">
                      <SocialButton
                        aria-label="instagram"
                        href="https://www.instagram.com/davidrheelaw/"
                        target="_blank"
                      >
                        <InstagramIcon fontSize="small" />
                      </SocialButton>
                      <SocialButton
                        aria-label="location"
                        href="https://maps.app.goo.gl/LwF9ukaVNKeSaaET9"
                        target="_blank"
                      >
                        <MapIcon fontSize="small" />
                      </SocialButton>
                    </Stack>
                  </Box>
                </Box>

                {/* Logo */}
                <LogoContainer>
                  {/* <motion.div
                    animate={{
                      y: [0, -5, 0],
                      transition: {
                        y: { repeat: 0, duration: 3, ease: "easeInOut" },
                      },
                    }}
                  > */}
                  <Box
                    component="img"
                    src={image}
                    alt="David Rhee Injury Law"
                    sx={{
                      width: "100%",
                      maxWidth: "220px",
                      height: "auto",
                      filter: "drop-shadow(0px 10px 15px rgba(26, 71, 137, 0.2))",
                    }}
                  />
                  {/* </motion.div> */}
                </LogoContainer>
              </ContactPaper>
            </motion.div>
          </Grid>
        </Grid>
      </Container>

      <ContactPopup handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} />
    </ContactSection>
  );
};

export default ContactPageMD;
