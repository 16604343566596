import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  InputAdornment,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";

// Styled components for form elements
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
    "&.Mui-focused": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1a4789",
        borderWidth: "2px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(26, 71, 137, 0.2)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#555",
    "&.Mui-focused": {
      color: "#1a4789",
    },
  },
  "& .MuiInputAdornment-root": {
    color: "#1a4789",
  },
}));

const StyledSelect = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
    "&.Mui-focused": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#1a4789",
        borderWidth: "2px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(26, 71, 137, 0.2)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#555",
    "&.Mui-focused": {
      color: "#1a4789",
    },
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#1a4789",
  color: "#FFFFFF",
  borderRadius: 0,
  padding: theme.spacing(1.5, 4),
  fontWeight: 500,
  position: "relative",
  overflow: "hidden",
  transition: "all 0.3s ease",
  textTransform: "none",
  letterSpacing: "0.5px",
  boxShadow: "0 4px 10px rgba(26, 71, 137, 0.2)",
  "&:hover": {
    backgroundColor: "#0d2e5e",
    transform: "translateY(-2px)",
    boxShadow: "0 6px 15px rgba(26, 71, 137, 0.3)",
  },
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: "2px solid #FAD461",
    opacity: 0,
    transition: "opacity 0.3s ease",
  },
  "&:hover:before": {
    opacity: 1,
  },
}));

const contactReasons = [
  "Auto Accident",
  "Premises Liability",
  "Wrongful Death",
  "Personal Injury",
  "Medical Malpractice",
  "Truck Accident",
  "Other",
];

export default function ContactForm(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  // Form validation
  const validateForm = () => {
    let isValid = true;

    // Basic name validation
    if (!name.trim()) {
      setNameError(true);
      isValid = false;
    } else {
      setNameError(false);
    }

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    return isValid;
  };

  // Encode data for Netlify url request
  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        name,
        email,
        phone,
        message,
        reason,
      }),
    })
      .then(() => {
        setLoading(false);
        props.setOpen(true);

        // Reset form
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setReason("");
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        console.error("Error submitting form:", error);
      });
  }

  const handleCloseError = () => {
    setError(false);
  };

  return (
    <>
      <form name="contact" netlify="true" onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container spacing={3}>
          {/* Personal Information */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="#1a4789" sx={{ mb: 1, fontWeight: 600 }}>
              Personal Information
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledTextField
              id="name"
              label="Full Name"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={nameError}
              helperText={nameError ? "Please enter your name" : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledTextField
              id="email"
              label="Email Address"
              variant="outlined"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              helperText={emailError ? "Please enter a valid email" : ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledTextField
              id="phone"
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <StyledSelect fullWidth variant="outlined">
              <InputLabel id="reason-label">Reason for Contact</InputLabel>
              <Select
                labelId="reason-label"
                id="reason"
                value={reason}
                label="Reason for Contact"
                onChange={(e) => setReason(e.target.value)}
              >
                {contactReasons.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </StyledSelect>
          </Grid>

          {/* Case Information */}
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="#1a4789" sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
              Case Information
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <StyledTextField
              id="message"
              label="Tell us about your case..."
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ alignSelf: "flex-start", mt: 1.5 }}>
                    <MessageIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <motion.div whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}>
                <SubmitButton
                  type="submit"
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} color="inherit" />}
                >
                  {loading ? "Submitting..." : "Submit Your Inquiry"}
                </SubmitButton>
              </motion.div>
            </Box>
          </Grid>
        </Grid>
      </form>

      {/* Error message */}
      <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: "100%" }}>
          There was an error submitting your form. Please try again.
        </Alert>
      </Snackbar>
    </>
  );
}
