import React, { useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Link,
  useMediaQuery,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Icons
import MapIcon from "@mui/icons-material/Map";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import GavelIcon from "@mui/icons-material/Gavel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Theme
import { useTheme } from "@mui/material/styles";
import PrivacyPolicy from "../components/Nav/PrivacyPolicy";

// Styled components
const FooterContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  background: "linear-gradient(45deg, #1a4789 0%, #0d2e5e 100%)",
  color: "#ffffff",
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(4),
}));

const FooterPatternOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: "url(/sandia2Dark.png)",
  backgroundPosition: "bottom center",
  backgroundSize: "cover",
  opacity: 0.05,
  zIndex: 1,
}));

const FooterContent = styled(Container)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
}));

const FooterHeading = styled(Typography)(({ theme }) => ({
  color: "#ffffff",
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  position: "relative",
  paddingBottom: theme.spacing(1),
  "&:after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "40px",
    height: "3px",
    background: "#FAD461",
    borderRadius: "2px",
  },
}));

const FooterItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: theme.spacing(2),
}));

const FooterIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  color: "#FAD461",
  marginTop: "2px",
}));

const SocialButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: theme.spacing(2),
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  color: "#ffffff",
  marginRight: theme.spacing(1),
  padding: theme.spacing(1),
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: "#FAD461",
    color: "#1a4789",
    transform: "translateY(-3px)",
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: "#ffffff",
  textDecoration: "none",
  position: "relative",
  transition: "all 0.3s ease",
  "&:hover": {
    color: "#FAD461",
    textDecoration: "none",
  },
}));

const PracticeItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 0),
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateX(5px)",
  },
}));

const PracticeLink = styled(Link)(({ theme }) => ({
  color: "#ffffff",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    color: "#FAD461",
    "& .MuiSvgIcon-root": {
      opacity: 1,
      transform: "translateX(2px)",
    },
  },
}));

const PracticeLinkIcon = styled(ArrowForwardIcon)(({ theme }) => ({
  fontSize: "0.8rem",
  marginLeft: theme.spacing(1),
  opacity: 0,
  transition: "all 0.3s ease",
}));

const ZiaSymbol = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "5%",
  bottom: "10%",
  opacity: 0.15,
  zIndex: 1,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const BottomBar = styled(Box)(({ theme }) => ({
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  paddingTop: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

const Footer = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Animation controls
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  // Practice areas data
  const practiceAreas = [
    { title: "Auto Accidents", href: "#Practice" },
    { title: "Premises Liability", href: "#Practice" },
    { title: "Medical Malpractice", href: "#Practice" },
    { title: "DUI/DWI Accidents", href: "#Practice" },
    { title: "Dog Bites", href: "#Practice" },
    { title: "Gun Violence", href: "#Practice" },
  ];

  // Quick links data
  const quickLinks = [
    { title: "Home", href: "#" },
    { title: "About", href: "#About" },
    { title: "Practice Areas", href: "#Practice" },
    { title: "Contact", href: "#Contact" },
  ];

  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer ref={ref}>
      <FooterPatternOverlay />
      {/* Zia Symbol Background Element */}
      <ZiaSymbol>
        <motion.img
          src="/ziaSymbol.avif"
          alt="Zia Symbol"
          width={250}
          height={250}
          animate={{
            rotate: [0, 360],
          }}
          transition={{
            duration: 120,
            ease: "linear",
            repeat: Infinity,
          }}
        />
      </ZiaSymbol>
      <FooterContent maxWidth="xl">
        <motion.div variants={containerVariants} initial="hidden" animate={controls}>
          <Grid container spacing={isMobile ? 4 : 6}>
            {/* Contact Information */}
            <Grid item xs={12} sm={6} md={4}>
              <motion.div variants={itemVariants}>
                <FooterHeading variant="h6">Contact Us</FooterHeading>

                <FooterItem>
                  <FooterIcon>
                    <MapIcon fontSize="small" />
                  </FooterIcon>
                  <Box>
                    <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
                      300 Central Ave. SW Suite 3000
                      <br />
                      Albuquerque, New Mexico 87102
                    </Typography>
                  </Box>
                </FooterItem>

                <FooterItem>
                  <FooterIcon>
                    <PhoneIcon fontSize="small" />
                  </FooterIcon>
                  <Box>
                    <FooterLink href="tel:+1-505-589-7008" variant="body2">
                      (505) 589-7008
                    </FooterLink>
                    <Typography variant="caption" sx={{ display: "block", color: "rgba(255,255,255,0.7)" }}>
                      Phone
                    </Typography>
                  </Box>
                </FooterItem>

                <FooterItem>
                  <FooterIcon>
                    <EmailIcon fontSize="small" />
                  </FooterIcon>
                  <Box>
                    <Typography variant="body2">(505) 273-8990</Typography>
                    <Typography variant="caption" sx={{ display: "block", color: "rgba(255,255,255,0.7)" }}>
                      Fax
                    </Typography>
                  </Box>
                </FooterItem>

                <SocialButtonContainer>
                  <SocialButton href="https://www.instagram.com/davidrheelaw/" target="_blank" aria-label="Instagram">
                    <InstagramIcon fontSize="small" />
                  </SocialButton>
                  <SocialButton
                    href="https://maps.app.goo.gl/LwF9ukaVNKeSaaET9"
                    target="_blank"
                    aria-label="Google Maps"
                  >
                    <MapIcon fontSize="small" />
                  </SocialButton>
                </SocialButtonContainer>
              </motion.div>
            </Grid>

            {/* Practice Areas */}
            <Grid item xs={12} sm={6} md={3}>
              <motion.div variants={itemVariants}>
                <FooterHeading variant="h6">Practice Areas</FooterHeading>
                <List disablePadding>
                  {practiceAreas.map((area, index) => (
                    <PracticeItem key={index} disableGutters>
                      <PracticeLink href={area.href}>
                        <GavelIcon sx={{ mr: 1, fontSize: "0.8rem" }} />
                        <ListItemText primary={area.title} primaryTypographyProps={{ variant: "body2" }} />
                        <PracticeLinkIcon />
                      </PracticeLink>
                    </PracticeItem>
                  ))}
                </List>
              </motion.div>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} sm={6} md={2}>
              <motion.div variants={itemVariants}>
                <FooterHeading variant="h6">Quick Links</FooterHeading>
                <List disablePadding>
                  {quickLinks.map((link, index) => (
                    <ListItem key={index} disableGutters sx={{ pb: 0.5 }}>
                      <FooterLink href={link.href}>
                        <Typography variant="body2">{link.title}</Typography>
                      </FooterLink>
                    </ListItem>
                  ))}
                  <ListItem key={"privacy-policy"} disableGutters sx={{ pb: 0.5 }}>
                    <FooterLink sx={{ cursor: "pointer" }} onClick={handleClickOpen}>
                      <Typography variant="body2">Privacy Policy</Typography>
                    </FooterLink>
                  </ListItem>
                </List>
              </motion.div>
            </Grid>

            {/* About & Logo */}
            <Grid item xs={12} sm={6} md={3}>
              <motion.div variants={itemVariants}>
                <FooterHeading variant="h6">About David Rhee Injury Law</FooterHeading>
                <Typography variant="body2" sx={{ mb: 2, lineHeight: 1.6 }}>
                  We provide high-quality legal services to the people of New Mexico. Our firm is dedicated to serving
                  clients who have suffered injury due to the negligence or wrongful acts of others.
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: "bold", color: "#FAD461" }}>
                  Hablamos Español
                </Typography>

                <Box sx={{ mt: 3 }}>
                  <motion.img
                    src="/DRLawLogo2025.png"
                    alt="David Rhee Law Logo"
                    style={{
                      maxWidth: "160px",
                      height: "auto",
                      filter: "brightness(1.2) drop-shadow(0px 5px 10px rgba(0,0,0,0.2))",
                    }}
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  />
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </motion.div>

        {/* Bottom Bar with Copyright */}
        <BottomBar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="rgba(255,255,255,0.7)">
                © {currentYear} Law Office of David Rhee. All Rights Reserved.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="caption"
                color="rgba(255,255,255,0.7)"
                sx={{ textAlign: { xs: "left", md: "right" }, display: "block" }}
              >
                This website is attorney advertising and is not meant as legal advice. Prior results do not guarantee
                similar outcomes.
              </Typography>
            </Grid>
          </Grid>
        </BottomBar>
      </FooterContent>
      <PrivacyPolicy handleClose={handleClose} open={open} />
    </FooterContainer>
  );
};

export default Footer;
