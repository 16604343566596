import React from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Divider,
  useMediaQuery,
  Dialog,
  IconButton,
  Slide,
  DialogContent,
  styled,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PrivacyPolicy = (props) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  // Styled components
  const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      borderRadius: 0,
      boxShadow: "0 20px 40px rgba(0, 0, 0, 0.2)",
      overflow: "hidden",
      background: "#ffffff",
    },
  }));

  const DialogHeader = styled(Box)(({ theme }) => ({
    position: "relative",
    backgroundColor: "#1a4789",
    color: "#ffffff",
    padding: theme.spacing(4, 2),
    textAlign: "center",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: "url(/sandia2Dark.png)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      opacity: 0.1,
      zIndex: 1,
    },
  }));

  const CloseButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: "#ffffff",
    zIndex: 10,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  }));

  const SectionTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    color: "#1a4789",
    fontWeight: 700,
    fontSize: "3rem",
    letterSpacing: "0.02em",
    position: "relative",
    paddingBottom: theme.spacing(2),
    textTransform: "none",
    fontFamily: "serif",
    lineHeight: 1.2,
    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",

    [theme.breakpoints.down("lg")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
  }));

  const SectionSubtitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    color: "#1a4789",
    fontWeight: 600,
    fontSize: "1.5rem",
    position: "relative",
    fontFamily: "serif",
    lineHeight: 1.3,

    [theme.breakpoints.down("md")]: {
      fontSize: "1.3rem",
    },
  }));

  return (
    <StyledDialog
      open={props.open}
      onClose={props.handleClose}
      fullScreen={isMobile}
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
      aria-labelledby="privacy-policy-dialog"
    >
      <CloseButton aria-label="close" onClick={props.handleClose}>
        <CloseIcon />
      </CloseButton>

      {/* Header Section */}
      <DialogHeader></DialogHeader>
      <DialogContent sx={{ p: isTablet ? 3 : 5 }}>
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <SectionTitle>
            Privacy Policy
            <span
              style={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: "80px",
                height: "4px",
                background: "linear-gradient(90deg, #FAD461, rgba(250, 212, 97, 0.5))",
                borderRadius: "4px",
              }}
            ></span>
          </SectionTitle>
          <Typography
            variant="subtitle1"
            sx={{
              maxWidth: 800,
              mx: "auto",
              color: "#555",
              fontSize: "1.1rem",
              mb: 2,
            }}
          >
            David Rhee Injury Law values your privacy and is committed to protecting your personal information.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              maxWidth: 700,
              mx: "auto",
              color: "#666",
              fontStyle: "italic",
            }}
          >
            Last Updated: {new Date().getFullYear()}
          </Typography>
        </Box>

        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 5 },
            borderRadius: 0,
            backgroundColor: "#fff",
            position: "relative",
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "4px",
              height: "100%",
              backgroundColor: "#1a4789",
            },
            mb: 4,
          }}
        >
          <SectionSubtitle>1. Information We Collect</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ mb: 3, color: "#333", lineHeight: 1.7 }}>
            We collect personal information that you voluntarily provide to us when you contact us through our website,
            by phone, or in person. This may include:
          </Typography>
          <Box sx={{ pl: 3, mb: 4 }}>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • Name, email address, phone number, and other contact details
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • Information about your legal matter, including details about accidents, injuries, and related
              circumstances
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • Any other information you choose to provide to us
            </Typography>
          </Box>

          <SectionSubtitle>2. How We Use Your Information</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ mb: 3, color: "#333", lineHeight: 1.7 }}>
            We use the information we collect primarily to provide legal services, which may include:
          </Typography>
          <Box sx={{ pl: 3, mb: 4 }}>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • Responding to your inquiries about our services
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • Providing legal advice and representation
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • Communicating with you about your case
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • Complying with legal and ethical obligations
            </Typography>
          </Box>

          <SectionSubtitle>3. Attorney-Client Privilege</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ mb: 4, color: "#333", lineHeight: 1.7 }}>
            Communications between you and David Rhee Injury Law are protected by attorney-client privilege. We maintain
            strict confidentiality of all information related to your legal matters in accordance with legal and ethical
            standards.
          </Typography>

          <SectionSubtitle>4. Information Sharing and Disclosure</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ mb: 3, color: "#333", lineHeight: 1.7 }}>
            We do not sell, rent, or trade your personal information to third parties. We may share your information in
            the following circumstances:
          </Typography>
          <Box sx={{ pl: 3, mb: 4 }}>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • With your consent
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • With courts, opposing parties, and others as necessary to represent you
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • To comply with legal obligations, court orders, or legal process
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
              • To protect the rights, property, or safety of our firm, our clients, or others
            </Typography>
          </Box>

          <SectionSubtitle>5. Website Analytics and Cookies</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ mb: 4, color: "#333", lineHeight: 1.7 }}>
            Our website may use cookies and similar technologies to enhance your experience, analyze usage patterns, and
            improve our services. You can control cookies through your browser settings, but this may affect some
            functionality of our website.
          </Typography>

          <SectionSubtitle>6. Data Security</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ mb: 4, color: "#333", lineHeight: 1.7 }}>
            We implement reasonable security measures to protect the personal information we collect. However, no method
            of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute
            security.
          </Typography>

          <SectionSubtitle>7. Your Rights</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ mb: 4, color: "#333", lineHeight: 1.7 }}>
            You have the right to access, correct, or delete your personal information. If you have questions about our
            privacy practices or wish to exercise these rights, please contact us using the information below.
          </Typography>

          <SectionSubtitle>8. Changes to This Privacy Policy</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ mb: 4, color: "#333", lineHeight: 1.7 }}>
            We may update this privacy policy periodically to reflect changes in our practices or legal requirements.
            The most current version will be posted on our website with the effective date.
          </Typography>

          <SectionSubtitle>9. Contact Us</SectionSubtitle>
          <Typography variant="body1" paragraph sx={{ color: "#333", lineHeight: 1.7 }}>
            If you have any questions or concerns about our privacy policy or practices, please contact us at:
          </Typography>
          <Box sx={{ pl: 3, mb: 2, mt: 2 }}>
            <Typography variant="body1" sx={{ color: "#333", fontWeight: 500 }}>
              David Rhee Injury Law
            </Typography>
            <Typography variant="body1" sx={{ color: "#333" }}>
              Phone: (505) 589-7008
            </Typography>
            <Typography variant="body1" sx={{ color: "#333" }}>
              Email: dr@davidrheelaw.com
            </Typography>
          </Box>
        </Paper>

        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography
            variant="body2"
            sx={{
              color: "#666",
              fontStyle: "italic",
            }}
          >
            This privacy policy is provided for informational purposes and constitutes our current data practices. While
            we strive to keep this policy accurate and up-to-date, it may not reflect the most current legal standards
            or requirements in your jurisdiction. This document should not be construed as legal advice. This privacy
            policy may be updated periodically without prior notice. The date of the last update is indicated at the top
            of this document. By continuing to use our services after changes to this policy, you acknowledge and accept
            these changes. This privacy policy has been reviewed by legal counsel licensed in New Mexico. Laws regarding
            privacy, data protection, and information security vary by jurisdiction and continue to evolve. If you
            access our services from outside New Mexico, please be aware that different or additional legal requirements
            may apply. © 2025 David Rhee Injury Law. All rights reserved.
          </Typography>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default PrivacyPolicy;
