import { Paper } from "@mui/material";
import NavBar from "./components/Nav/NavBar";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import theme from "./Theme/theme";
import { ThemeProvider } from "@mui/material/styles";
import Contact from "./Pages/Contact";
import Footer from "./Pages/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import GradientTransition from "./components/Transitions/GradientTransition";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <Paper
          sx={{
            mr: xl ? 40 : 0,
            ml: xl ? 40 : 0,
            background: "radial-gradient(circle at 70% 50%, #a2c0ff 0%, #e8effd 30%, #e8effd 60%, #e0eaff 100%)",
          }}
        >
          <NavBar />
          <Home />
          <GradientTransition topColor="#a2c0ff" bottomColor="#e8effd" />
          <Services />
          <Contact />
          <About />
          <Footer />
        </Paper>
      </ParallaxProvider>
    </ThemeProvider>
  );
}

export default App;
