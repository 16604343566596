import React from "react";
import { Typography, Box, Container, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// Background image path
const backgroundImage = "/ziaDark.png";
const profileImage = "/daveProfileNoBackground.png";

// Styled components
const AboutSection = styled(Box)(({ theme }) => ({
  backgroundColor: "#e8effd",
  position: "relative",
  overflow: "hidden",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
}));

const ProfileImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "0",
  overflow: "hidden",
  boxShadow: "0 20px 40px rgba(0, 0, 0, 0.3)",
  height: "100%",
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: "left",
  backgroundSize: "cover",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 1,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    width: "5px",
    height: "100%",
    background: "linear-gradient(to bottom, #FAD461, #1a4789)",
    zIndex: 3,
  },
}));

const ProfileImg = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  transition: "transform 0.5s ease",
  position: "relative",
  zIndex: 2,
  "&:hover": {
    transform: "scale(1.03)",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: "#1a4789",
  fontWeight: 700,
  fontSize: "3.5rem",
  textShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
  letterSpacing: "0.02em",
  position: "relative",
  paddingBottom: theme.spacing(2),
  textTransform: "none",
  fontFamily: "serif",
  lineHeight: 1.2,
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "4px",
    width: "80px",
    background: "linear-gradient(90deg, #FAD461, rgba(250, 212, 97, 0.5))",
    borderRadius: "4px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "2.5rem",
    textAlign: "center",
    "&::after": {
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
}));

const ContentText = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  lineHeight: 1.8,
  color: "#333333",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));

const AboutMD = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  return (
    <AboutSection id="About" ref={ref}>
      <ContentContainer maxWidth="lg">
        <Grid container spacing={6} alignItems="center" justifyContent="center">
          {/* Image Column */}
          <Grid item xs={12} md={5}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <ProfileImageWrapper>
                <ProfileImg src={profileImage} alt="David Rhee, Attorney at Law" />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 20,
                    right: 20,
                    zIndex: 3,
                    background: "rgba(26, 71, 137, 0.8)",
                    px: 2,
                    py: 1,
                    borderRight: "3px solid #FAD461",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" color="white">
                    David Rhee
                  </Typography>
                  <Typography variant="body2" color="white">
                    Attorney at Law
                  </Typography>
                </Box>
              </ProfileImageWrapper>
            </motion.div>
          </Grid>

          {/* Text Column */}
          <Grid item xs={12} md={7}>
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
            >
              <SectionTitle>About David Rhee</SectionTitle>
              <ContentText paragraph>
                David Rhee is a dedicated attorney who is passionate about serving the New Mexico community. Born and
                raised in Albuquerque, David graduated from Albuquerque High School before earning his bachelor's degree
                from The University of New Mexico.
              </ContentText>
              <ContentText paragraph>
                He went on to obtain his Juris Doctorate degree from The University of New Mexico School of Law. As a
                fluent Spanish speaker and conversational Portuguese speaker, David is uniquely equipped to serve the
                needs of Spanish-speaking and immigrant communities.
              </ContentText>
              <ContentText paragraph>
                He is also a family man and devout Christian. When he's not working hard for his clients, David enjoys
                spending time with his wife and two sons, as well as hiking, biking, snowboarding, and exploring the
                great outdoors.
              </ContentText>
            </motion.div>
          </Grid>
        </Grid>

        {/* Decorative element - New Mexico-inspired divider */}
        {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={inView ? { opacity: 0.8, scale: 1 } : { opacity: 0, scale: 0.8 }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            <Box
              component="img"
              src="/ziaSymbol.avif"
              alt="Zia Symbol"
              sx={{
                height: 100,
                filter: "drop-shadow(0px 0px 8px rgba(26, 71, 137, 0.3))",
              }}
            />
          </motion.div>
        </Box> */}
      </ContentContainer>
    </AboutSection>
  );
};

export default AboutMD;
